<template>
  <div id="BrandZoneList">
    <!-- 品牌列表 -->
    <Header id="Header" class="BrandZone_Header"></Header>
    <div class="list_box">
      <div class="center_list">
        <div class="topGroup">
          <div class="toplable">品牌：</div>
          <div
            :class="activeIndex === index ? 'Group1' : 'Group'"
            v-for="(item, index) in listData"
            :key="index"
            @click="seclet(index)"
          >
            <div class="text">{{ item.title }}</div>
          </div>
        </div>
        <div class="bottomGroup">
          <div class="bottomlable">品类：</div>
          <div
            class="GroupBottomItem"
            v-for="(item, index) in childData"
            :key="index"
            @click="childSelcet(item, index)"
          >
            <div
              :class="
                childActiveIndex === index ? 'bottom_item1' : 'bottom_item'
              "
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="data_for_box">
      <div
        class="item_box"
        v-for="(item, index) in setData"
        :key="index"
        @click="todetails(item)"
      >
        <div class="top_img">
          <img class="my_cover" :src="item.image[0]" alt="" />
        </div>
        <div class="name">{{ item.name }}</div>
        <div class="info">{{ item.info }}</div>
        <div class="price">￥{{ item.price }}</div>
      </div>
    </div>
    <div class="pagetion_BrandZoneList">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="limit"
        background
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <div class="el_queding">确定</div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header";
import { getProductGroup, getProductList } from "../../http/api";
export default {
  name: "",
  props: {},
  components: {
    Header,
  },
  data() {
    return {
      listData: [], //父级分类数据
      childData: [], //子分类数据
      activeIndex: Number(this.$route.query.active), //父级分类选择下标
      childActiveIndex: 0, //子分类选择下标
      childActiveName: '', //子分类选择名称
      group_parent_id: 0, //父级分类id
      group_id: 0, //子分类id
      page: 1, //默认第几页开始
      limit: 16, //默认每页多少条
      setData: [],
      total: 0, //总条数
      last_page: 0, //最后一页
      current_page: 0, //当前页
    };
  },

  methods: {
    handleSizeChange() {},
    handleCurrentChange(page) {
      // this.page = page;
      this.getProductListData();
    },
    //点击去详情页面
    todetails(item) {
      this.$router.push({
        path: "/BrandZoneDetail",
        query: { id: item.id,active:this.childActiveName },
      });
    },
    //   品牌分类点击回调
    seclet(index) {
      this.activeIndex = index;
      this.$router.push({
        path:'/BrandZoneList',
        query:{
          active:this.activeIndex
        }
      })
      // this.childData = this.listData[this.activeIndex].child;
      // this.group_id = this.childData[this.childActiveIndex].id;
      // this.group_parent_id = this.listData[this.activeIndex].id;
      // this.getProductListData();
    },
    // 品类点击选择回调
    childSelcet(item, index) {
      this.childActiveIndex = index;
      this.childActiveName = item.title
      this.group_id = item.id;
      this.getProductListData();
    },
    // 获取数据列表请求封装
    getProductListData() {
      getProductList({
        group_parent_id: this.group_parent_id,
        group_id: this.group_id,
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        this.setData = res.data.data;
        (this.total = res.data.total),
          (this.current_page = res.data.current_page);
      });
    },
  },
  mounted() {
    $(".el-pagination__jump").css({
      fontSize: "15px",
    });
    //   获取分类列表
    getProductGroup().then((res) => {
      this.group_parent_id = res.data[this.activeIndex].id;
      this.listData = res.data;
       this.childActiveName = this.listData[this.activeIndex].title
      this.childData = res.data[this.activeIndex].child;
      this.group_id = this.childData[this.childActiveIndex].id;
      this.getProductListData();
    });
  },
  watch:{
    $route(to,from){
      if(to.query.active!=from.query.active){
        getProductGroup().then((res) => {
          this.group_parent_id = res.data[this.activeIndex].id;
          this.listData = res.data;
          this.childActiveName = this.listData[this.activeIndex].title
          this.childData = res.data[this.activeIndex].child;
          this.group_id = this.childData[this.childActiveIndex].id;
          this.getProductListData();
        });
      }
    },
  },
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#BrandZoneList {
  .list_box {
    width: 1200px;
    height: 154px;
    margin: 45px auto 21px auto;
    background: #ffffff;
    border: 1px solid rgba(112, 112, 112, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    .center_list {
      width: 1119px;
      height: 105px;
      .topGroup {
        width: 100%;
        height: 56px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.2);
        display: flex;
        .toplable {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 56px;
          color: #333333;
          margin-right: 49px;
        }
        .Group {
          height: 34px;
          background: transparent;
          border-radius: 8px;
          margin-right: 30px;
          cursor: pointer;
          .text {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 34px;
            color: #666666;
            margin: 0 20px 0 20px;
          }
        }
        .Group1 {
          height: 34px;
          background: #005a3f;
          border-radius: 8px;
          cursor: pointer;
          margin-right: 30px;

          .text {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 34px;
            color: #ffffff;
            margin: 0 20px 0 20px;
          }
        }
      }
      .bottomGroup {
        width: 100%;
        height: 51px;
        display: flex;
        align-items: center;
        .bottomlable {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 51px;
          color: #333333;
          margin-right: 49px;
        }
        .GroupBottomItem {
          height: 34px;
          .bottom_item {
            border-radius: 8px;
            margin: 0 20px 0 20px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 34px;
            color: #666666;
            cursor: pointer;
            margin-right: 30px;
          }
          .bottom_item1 {
            border-radius: 8px;
            margin: 0 20px 0 20px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 34px;
            color: #005a3f;
            margin-right: 30px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .data_for_box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .item_box:nth-child(4n) {
      margin-right: 0;
    }
    .item_box:hover {
      border: 1px solid rgba(0, 90, 63, 0.34901960784313724);
      box-shadow: 0px 3px 6px rgba(0, 90, 63, 0.34);
      .name {
        transition: all 0.2s linear;
        color: #005a3f;
      }
      .top_img{
        .my_cover{
          transform: scale(1.2);
          transition: all 0.5s linear;
        }
      }
    }
    .item_box {
      //   width: 291px;
      transition: all 0.5s linear;
      width: 24.25%;
      height: 330px;
      border: 1px solid #ebebeb;
      margin-bottom: 50px;
      cursor: pointer;
      margin-right: 8px;
      overflow: hidden;
      .top_img {
        width: 100%;
        height: 185px;
        overflow: hidden;
        transition: all 0.5s linear;
      }
      .price {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        text-align: center;
      }
      .name {
        transition: all 0.2s linear;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-top: 12px;
        text-align: center;
        padding: 0 10px;
      }
      .info {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin: 15px 0 24px 0;
        text-align: center;
      }
    }
  }
  .pagetion_BrandZoneList {
    width: 1200px;
    height: 46px;
    margin: 52px auto 162px auto;
    display: flex;
    justify-content: center;
    .el_queding {
      width: 80px;
      height: 46px;
      border: 1px solid #e5e5e5;
      text-align: center;
      line-height: 46px;
      font-size: 15px;
      color: #999999;
      margin-left: 14px;
      cursor: pointer;
    }
  }
}
</style>